(function($) {

    $.easing.def = 'easeInOutQuart';

    var roundedNav_offset;
    var header_offset;

    var lastScrollTop, st = 0;

    function init(){

        // SELECT FIELDS TUNING =)
        
        if($('select').length){''
            $('select').select2();
        }

        // Slider Overlflow inspiratie PT1

        if($('.slider-overflow').length){

            $('.slider-overflow').each(function(){
                var centerPaddingLarge = 15;
                if($(window).width() > 1200){ centerPaddingLarge = ($(window).width() - 1200) / 2; }

                var $slider_overflow = $(this);
                var slider_overflow_size = $('.slider-overflow li').length;

                var slide_count = $slider_overflow.find('.card').length;
                var slide_min, init_slide;
                if($(window).width() >= 1025 && $(window).width() < 1440){ slide_min = 3; }
                else if($(window).width() >= 1440){ slide_min = 5; }
                else { slide_min = 2; }

                console.log('slide_min :' + slide_min);
                console.log('slide_count :' + slide_count);

                if (slide_count >= slide_min) {
                    console.log($(window).width());
                    if($(window).width() >= 640 && $(window).width() < 1025){ init_slide = 1; }
                    else if($(window).width() >= 1025){ init_slide = 2; }
                    else { init_slide = 0; }
                } 
                else if(slide_count < (slide_min - 1)) { 
                    if($(window).width() >= 640 && $(window).width() < 1025){ init_slide = slide_min - slide_count - 1; }
                    else if($(window).width() >= 1024){ init_slide = slide_min - slide_count - 1; }
                    else { init_slide = 0; }
                } 
                else {
                    init_slide = 0;
                }

                console.log('init_slide: ' + init_slide);

                $slider_overflow.slick({
                    centerMode: true,
                    centerPadding: centerPaddingLarge + 'px',
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: false,
                    dots: false,
                    arrows: false,
                    initialSlide: init_slide,
                    responsive: [
                        {
                        breakpoint: 1025,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            initialSlide: init_slide,
                            centerPadding: '40px',
                            }
                        },
                        {
                        breakpoint: 640,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            centerPadding: '20px',
                            initialSlide: init_slide
                            }
                        }
                    ]
                });
            });

        }

        if($('.content--block-slider').length){

            var $blockslider = $('.content--block-slider');
            $blockslider.slick({
                centerMode: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
                arrows: true,
                centerPadding: '80px',
                responsive: [
                    {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1,
                        centerMode: false,
                        }
                    },
                    {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: false,
                        }
                    }
                ]
            });

        }

        


        if($('.roundedNav').length){ roundedNav_offset = ( $(window).height() - $('.roundedNav').height() ) / 2; }

        if($('.yourFloor--nav').length){ 

            $('.yourFloor--nav').css({
                'position': 'absolute',
                'top': roundedNav_offset + 'px',
                '-webkit-transform': 'none',
                '-moz-transform': 'none',
                '-ms-transform': 'none',
                '-o-transform': 'none',
                'transform': 'none'
            });

        }

        if($('.whyNobel--nav').length){ 

            $('.whyNobel--nav').css({
                'position': 'absolute',
                'top': $(window).height() + roundedNav_offset + 'px',
                '-webkit-transform': 'none',
                '-moz-transform': 'none',
                '-ms-transform': 'none',
                '-o-transform': 'none',
                'transform': 'none'
            });

        }

        if($('.chapters--nav').length){ 

            $('.chapters--nav').css({
                'position': 'absolute',
                'top': '0',
                'bottom': 'auto'
            });

        }


        if($('.yourFloor--step').length){

            $('.yourFloor--step').each(function(){

                var index = $(this).index();
                var current_id = $(this).attr('id');

                new Waypoint({
                    element: $(this),
                    handler: function(direction){
                        updateNav(index);
                    },
                    offset: '40%'
                });

                new Waypoint.Inview({
                    element: $(this),
                    exited: function(direction){
                        if(direction === 'up'){
                            updateNav(index-1);
                        }
                    }
                });

            });

        }

        if($('.whyNobel--block').length){

            $('.whyNobel--block').each(function(){
                var index = $(this).index();
                var current_id = $(this).attr('id');
                new Waypoint({
                    element: $(this),
                    handler: function(direction){
                        if(current_id !== 'whyBlock-1'){
                            $('.whyNobel--nav').toggleClass('roundedNav-inversed');
                        }
                        updateNav(index);
                    },
                    offset: '40%'
                });
            });
            
        }

        if($('.chapter').length){

            $('.chapter').each(function(){
                var index = $(this).index();
                var current_id = $(this).attr('id');

                // new Waypoint({
                //     element: $(this),
                //     handler: function(direction){
                //         updateNav(index - 2);
                //     },
                //     offset: '40%'
                // });

                new Waypoint({
                    element: $(this),
                    handler: function(direction){
                        updateNav(index - 3);
                    },
                    offset: '40%'
                });

                new Waypoint.Inview({
                    element: $(this),
                    exited: function(direction){
                        if(direction === 'up'){
                            updateNav(index - 4);
                        }
                    }
                });
            });

        }

    }

    /////////
    //////////////////
    ///////// 

    var hide_dropdown_timer, show_dropdown_timer;

    $(window).scroll(function(event){
        st = $(this).scrollTop();
        $('.menu-item-has-children .is-visible').removeClass('is-visible');
        if(st > 100){
            if(st > lastScrollTop){
                $('.header').addClass('is-invisible');
            }else{
                $('.header').removeClass('is-invisible');
                $('body').addClass('user-has-scrolled');
            }
        }else{
            $('body').removeClass('user-has-scrolled');
            $('.header').removeClass('is-invisible');
        }
        lastScrollTop = st;
    });

    $('.header--nav .menu-item-has-children > a').on('mouseenter', function(){
        clearTimeout(hide_dropdown_timer);
        var $self = $(this);
        var $child = $self.closest('.menu-item-has-children').find('ul');
        var time = 0; if(st < (header_offset + 100)){ time = 400; 
            console.log("started timer"); }else{ console.log("no timer necessary"); }
        show_dropdown_timer = setTimeout(function(){
            //console.log("timer ended");
            $child.addClass('is-visible');
        },time);
    });

    $('.header--nav > ul > li').not('.menu-item-has-children').find('a').on('mouseenter', function(){
        clearTimeout(hide_dropdown_timer);
        //console.log("ended timer");
        $('.menu-item-has-children ul.is-visible').each(function(){
            $(this).removeClass('is-visible');
        });
    });

    $('.header').on('mouseleave', function(){
        clearTimeout(show_dropdown_timer);
        hide_dropdown_timer = setTimeout(function(){
            $('.menu-item-has-children .is-visible').removeClass('is-visible');
        },2000);
    });

    $('.header--nav .menu-item-has-children > ul').on('mouseleave', function(){
        var $self = $(this);
        var $child = $self.closest('.menu-item-has-children').find('ul');
        $child.removeClass('is-visible');
    });

    /////////
    //////////////////
    ///////// 

    $('.hamburger').on('click',function(e){
        $(document).unbind('touchmove');
        $('body').toggleClass('nav-is-open');
        if($('body').hasClass('nav-is-open')){
            $(document).bind('touchmove',function(e){
                e.preventDefault();
            });
        }else{
            $(document).unbind('touchmove');
        }

    });

    $('.header--nav .menu-item-has-children > a').on('click',function(e){
        e.preventDefault();
        var $clicked = $(this);
        $clicked.closest('li').addClass('child-is-open');
        $('.header--nav').addClass('has-open-child');
        $('.header--nav .shade').fadeIn('fast');
        $('.header--menuToggle').hide();
    });

    $('.header--nav .shade, .closeChild').on('click',function(){
        $('.child-is-open').removeClass('child-is-open');
        $('.has-open-child').removeClass('has-open-child');
        $('.header--nav .shade').fadeOut('fast');
        setTimeout(function(){
            $('.header--menuToggle').show();
        }, 200);
    });

    

    $(window).on('load resize',function(){

        if($('.technical--wrapper').length){
            if($(window).width() > 640){
              $('.technical--specifications').foundation('toggle', $('.accordion-content'));
            } else {
              $('.technical--specifications').foundation('up', $('.accordion-content'));
            }
        }

        if($('.fullheight').length){
            $('.fullheight').each(function(){
                $(this).css('height', $(window).height() + 'px');
            });
        }

        if($('.whyNobel .fullheight').length){
            $('.whyNobel .fullheight').each(function(){
                if(Foundation.MediaQuery.Current === 'small'){
                    $(this).css('min-height', $(window).height() + 'px');
                }else{
                    $(this).css('height', $(window).height() + 'px');
                }
            });
        }

        if($('.slider-cards').length){

            var $slider_cards = $('.slider-cards');
            var slider_cards_settings = {
                dots: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                // autoplay: true,
                // autoplaySpeed: 7000,
                speed: 500,
                arrows: false,
            };

            if(Foundation.MediaQuery.current == 'small'){
                if (!$slider_cards.hasClass('slick-initialized')) {
                    return $slider_cards.slick(slider_cards_settings);
                }
            }else{
                if ($slider_cards.hasClass('slick-initialized')) {
                    $slider_cards.slick('unslick');
                }
                return;
            }

        }

        if($('.roundedNav').length){ roundedNav_offset = ( $(window).height() - $('.roundedNav').height() ) / 2; }

    });

    $('.pageheader .arrow-down').on('click',function(e){
        e.preventDefault();
        var $parent = $(this).closest('.pageheader');
        $('html, body').animate({
            scrollTop: $parent.next().offset().top
        }, 1000);
    });

    $('.filterBar li.active a').on('click',function(e){
        e.preventDefault();
        $(this).closest('.filterBar').toggleClass('is-open');
    });

    $('.team--block a').on('click',function(e){ e.preventDefault(); });

    // Slider Overlflow inspiratie PT2

    $('.slider-overflow').each(function(){

        var $self = $(this);
        var $parent = $self.closest('.loop-inspirationCategory');
        var $pagination = $parent.find('.slider-overflow--pagination');
        var $slider = $parent.find('.slider-overflow');

        var incr = 2, offset = 0;
        if($(window).width() >= 1025){ incr = 4; offset = 1; }

        var has_pages = false;

        var slide_count = $self.find('.card').length;
        var loop_count = 4;
        var slide_min, slider_func_needed = false;
        if($(window).width() >= 640 && $(window).width() < 1025){ slide_min = 3; }
        else if($(window).width() >= 1025){ slide_min = 5; }
        else { slide_min = 2; }

        if(slide_count >= slide_min) { slider_func_needed = true; }

        var end_fix = false;

        if(slider_func_needed) {
            for (var i = 0; i < slide_count; i++){
                if(i && (i % 4 === 0)){
                    if(!has_pages){ has_pages = true; }
                    $pagination.append('<li><button>0' + ((i / 4) + 1) + '</button></li>');
                }
            }
        } else {
            $pagination.hide();
            $parent.find('.slider-overflow--nextSlide').hide();
        }

        $pagination.find('button').on('click',function(){
            if(has_pages && slider_func_needed){

                var $clicked = $(this);
                var clicked_index = ($clicked.closest('li').index() * 4) + 1;

                if( ($clicked.closest('li').index() + 1) < $pagination.find('li').length || $pagination.find('li').length == (slide_count / 4)){
                    $slider.slick('slickGoTo', clicked_index + 1);
                    end_fix = false;
                }else{
                    $slider.slick('slickGoTo', slide_count - ((($clicked.closest('li').index() + 1) * 4) - slide_count) + 1 );
                    end_fix = true;
                }

                if(!$clicked.hasClass('is-active')){
                    $parent.find('.is-active').removeClass('is-active');
                    $clicked.addClass('is-active');
                }

            }
        });

        $parent.find('.slider-overflow--nextSlide').on('click',function(){

            if(has_pages && slider_func_needed){

                var $clicked = $(this);
                var current_slide = $slider.slick('slickCurrentSlide');

                if( (slide_count - current_slide) > incr){
                    $slider.slick('slickGoTo', current_slide + incr);
                    current_slide = $slider.slick('slickCurrentSlide');
                    end_fix = false;
                }else{
                    $slider.slick('slickGoTo', slide_count - incr + 1 + offset);
                    end_fix = true;
                }

            }

        });

        $slider.on('afterChange',function(){

            var current_slide = $slider.slick('slickCurrentSlide');
            var current_page = Math.floor(((current_slide - 1 - offset) / incr));

            if((current_slide + incr - 1 - offset) < slide_count){ end_fix = false; }else{ end_fix = true; }

            if(current_page >= 0){
                if(!end_fix){
                    $pagination.find('.is-active').removeClass('is-active'); 
                    $pagination.find('li').eq(current_page).find('button').addClass('is-active');
                    $parent.find('.slider-overflow--nextSlide').removeClass('is-invisible');
                }else{
                    $parent.find('.slider-overflow--nextSlide').addClass('is-invisible');
                    $parent.find('.is-active').removeClass('is-active');
                    $pagination.find('li').eq($pagination.find('li').length - 1).find('button').addClass('is-active');
                }
            }

            if(Foundation.MediaQuery.atLeast('medium')){
                if(current_slide < (1 + offset)){
                    setTimeout(function(){
                        $slider.slick('slickGoTo', 1 + offset);
                    },1);
                }

                if((current_slide + 1 + offset) > slide_count){
                    setTimeout(function(){
                        $slider.slick('slickGoTo', slide_count - incr + 1 + offset);
                    },1);
                }
            }

        });

    });


    if($('.whyNobel--nav.roundedNav').length && $('.whyNobel--block').length){

        var $nav = $('.whyNobel--nav.roundedNav ul').eq(0);
        var block_count = $('.whyNobel--block').length;

        for (var i = 0; i < block_count; i++){
            $nav.append('<li><button>0' + (i + 1) + '</button></li>');
        }

        $('.whyNobel--nav li').eq(0).find('button').addClass('is-current');

    }

    $('.whyNobel--nav button').on('click',function(){

        var $clicked = $(this);
        var index = $clicked.closest('li').index();

        if(!$clicked.hasClass('is-current')){
            updateNav(index);
            goToWhyBlock(index);
        }

    });

    $('.whyNobel--block .arrow-down').on('click',function(e){

        e.preventDefault();

        var $clicked = $(this);
        var $parent = $clicked.closest('.whyNobel--block');
        var index = $parent.next().index();

        setTimeout(function(){ updateNav(index); },600);
        goToWhyBlock(index);

    });

    if($('.whyNobel--block').length){

        var firstBlock = new Waypoint.Inview({
            element: $('.whyNobel--block').eq(0),
            exit: function(direction){
                if(direction === 'up'){
                    $('.whyNobel--nav').css({
                        'position': 'absolute',
                        'top': $(window).height() + roundedNav_offset + 'px',
                        '-webkit-transform': 'none',
                        '-moz-transform': 'none',
                        '-ms-transform': 'none',
                        '-o-transform': 'none',
                        'transform': 'none'
                    });
                }
            }
        });

        var secondBlock = new Waypoint.Inview({
            element: $('.whyNobel--block').eq(1),
            enter: function(direction){
                $('.whyNobel--nav').css({
                    'position': 'fixed',
                    'top': '50%',
                    '-webkit-transform': 'translateY(-50%)',
                    '-moz-transform': 'translateY(-50%)',
                    '-ms-transform': 'translateY(-50%)',
                    '-o-transform': 'translateY(-50%)',
                    'transform': 'translateY(-50%)'
                });
            }
        });

        var lastBlock = new Waypoint.Inview({
            element: $('.whyNobel--block').eq(6),
            entered: function(direction){
                $('.whyNobel--nav').css({
                    'position': 'absolute',
                    'top': ($(window).height() * 7) + roundedNav_offset + 'px',
                    '-webkit-transform': 'none',
                    '-moz-transform': 'none',
                    '-ms-transform': 'none',
                    '-o-transform': 'none',
                    'transform': 'none'
                });
            }
        });

        var stlastBlock = new Waypoint.Inview({
            element: $('.whyNobel--block').eq(5),
            enter: function(direction){
                if(direction === 'up'){
                    $('.whyNobel--nav').css({
                        'position': 'fixed',
                        'top': '50%',
                        '-webkit-transform': 'translateY(-50%)',
                        '-moz-transform': 'translateY(-50%)',
                        '-ms-transform': 'translateY(-50%)',
                        '-o-transform': 'translateY(-50%)',
                        'transform': 'translateY(-50%)'
                    });
                }
            }
        });

    }

    if($('.yourFloor').length){

        var your_floor = new Waypoint.Inview({
            element: $('.yourFloor'),
            entered: function(direction){
                if(direction === 'down'){
                    $('.yourFloor--nav').css({
                        'position': 'absolute',
                        'top': 'auto',
                        'bottom': roundedNav_offset + 'px',
                        '-webkit-transform': 'none',
                        '-moz-transform': 'none',
                        '-ms-transform': 'none',
                        '-o-transform': 'none',
                        'transform': 'none'
                    });
                }
            },
            exit: function(direction){
                $('.yourFloor--nav').css({
                    'position': 'fixed',
                    'top': '50%',
                    'bottom': 'auto',
                    '-webkit-transform': 'translateY(-50%)',
                    '-moz-transform': 'translateY(-50%)',
                    '-ms-transform': 'translateY(-50%)',
                    '-o-transform': 'translateY(-50%)',
                    'transform': 'translateY(-50%)'
                });
            }
        });

        var pageheader = new Waypoint.Inview({
            element: $('.pageheader'),
            enter: function(direction){
                if(direction === 'up'){
                    $('.yourFloor--nav').css({
                        'position': 'absolute',
                        'top': roundedNav_offset + 'px',
                        'bottom': 'auto',
                        '-webkit-transform': 'none',
                        '-moz-transform': 'none',
                        '-ms-transform': 'none',
                        '-o-transform': 'none',
                        'transform': 'none'
                    });
                }
            }
        });

    }

    $('.yourFloor--nav button').on('click',function(){

        var $clicked = $(this);
        var index = $clicked.closest('li').index();
        var cur_index = $('.roundedNav .is-current').closest('li').index();

        if(!$clicked.hasClass('is-current')){
            updateNav(index);
            goToFloorStep(index,cur_index);
        }

    });

    if($('.chapters').length){

        var navPagination = new Waypoint.Inview({
            element: $('.navPagination'),
            enter: function(direction){
                if(direction == 'down'){
                    $('.chapters--nav').css({
                        'position': 'absolute',
                        'top': 'auto',
                        'bottom': '0'
                    });
                }
            },
            exited: function(direction){
                if(direction == 'up'){
                    $('.chapters--nav').css({
                        'position': 'fixed',
                        'top': 'auto',
                        'bottom': '0'
                    });
                }
            }
        });

        var pageheader = new Waypoint.Inview({
            element: $('.pageheader'),
            enter: function(direction){
                if(direction === 'up'){
                    $('.chapters--nav').css({
                        'position': 'absolute',
                        'top': '0',
                        'bottom': 'auto'
                    });
                }
            },
            exited: function(direction){
                if(direction == 'down'){
                    $('.chapters--nav').css({
                        'position': 'fixed',
                        'top': '0',
                        'bottom': 'auto'
                    });
                }
            }
        });

    }

    $('.chapters--nav ul button').on('click',function(e){

        e.preventDefault();

        var $clicked = $(this);
        var index = $clicked.closest('li').index();

        if(!$clicked.hasClass('is-current')){
            updateNav(index - 1);
            goToChapter(index - 1);
        }

    });

    $('.chapters .roundedNav--up').on('click', function(e){

        e.preventDefault();

        updateNav(0);
        goToChapter(0);

    });

    function goToWhyBlock(index){
        $('html, body').animate({
            scrollTop: $('.whyNobel--block').eq(index).offset().top
        }, 1200);
    }

    function updateNav(index){

        $('.roundedNav .is-current').removeClass('is-current');
        $('.roundedNav li').eq(index).find('button, a').addClass('is-current');

    }

    function goToFloorStep(index,cur_index){

        var scroll_offset = 50;
        if(index < cur_index){ console.log(index); scroll_offset = header_offset + 50; }

        $('html, body').animate({
            scrollTop: $('.yourFloor--step').eq(index).offset().top - scroll_offset
        }, 1200, function(){ updateNav(index) });

    }

    function goToChapter(index){
        $('html, body').animate({
            scrollTop: $('.chapter').eq(index).offset().top - 50
        }, 1200);
    }


    // Replace quantity up/down buttons

    $('<div class="quantity-nav"><div class="quantity-button quantity-up"></div><div class="quantity-button quantity-down"></div></div>').insertAfter('.quantity input');

    $('.quantity').each(function() {
    	var spinner = $(this),
        input = spinner.find('input[type="number"]'),
        btnUp = spinner.find('.quantity-up'),
        btnDown = spinner.find('.quantity-down'),
        min = input.attr('min'),
        max = input.attr('max');
    	
	      btnUp.click(function() {
	        var oldValue = parseFloat(input.val());
	        if (oldValue >= max) {
	          var newVal = oldValue;
	        } else {
	          var newVal = oldValue + 1;
	        }
	        input.val(newVal);
	        input.trigger("change");
	      });
	
	      btnDown.click(function() {
	        var oldValue = parseFloat(input.val());
	        if (oldValue <= min) {
	          var newVal = oldValue;
	        } else {
	          var newVal = oldValue - 1;
	        }
	        input.val(newVal);
	        input.trigger("change");
	      });

    });

    // Woocommerce Ajax quantity
    $( "form.quantity-form" ).on( "change", 'input[type="number"]', function( e ) {
        var direction = this.defaultValue < this.value
        var productID = $(this).attr('name');
        this.defaultValue = this.value;
        var quantity = $(this).val();
        
        var data = {
    			action: 	'nobel_set_product_cart_quantity',
    			product_id:	productID,
    			quantity:	quantity,
    	};
		$.post(
				wc_add_to_cart_params.ajax_url, 
				data, 
				function(response){
					console.log(response);
					
					refresh_cart();
				}
		);
    });
    
    function refresh_cart(){
    	var data = {
    			action: 'nobel_update_mini_cart'
    	};
		$.post(
				wc_add_to_cart_params.ajax_url, 
				data, 
				function(response){
					$('#mini-cart').replaceWith(response); 
				}
		);
    }

    //Wpml active lang first
    $(function(){
        $(".wpml-ls-current-language").prependTo(".wpml-ls ul");
    });
    

    $(function() {
        $toggle = false;
        $('.roundedNav-chapters li button').hover(function() {
            if ($toggle == false){
                $toggle = true;
                $('.chapter--overlay').fadeIn( 400, function() {
                    $toggle = false;
                });
            }

        }, function() {

            if ($toggle == false){
                $toggle = true;
                $('.chapter--overlay').fadeOut( 400, function() {
                    $toggle = false;
                });
            }
        });

    });

    function getHeaderOffset(){
        if(Foundation.MediaQuery.Current === 'small'){ header_offset = 72; }else
        if(Foundation.MediaQuery.Current === 'medium'){ header_offset = 109; } else
        if(Foundation.MediaQuery.atLeast('large')){ header_offset = 104; }
    }

    $(window).on('load resize', function(){ getHeaderOffset(); });

    $(document).ready(function(){

        init();
        
        $('.cn-more-info').removeClass('button')
        $('.cn-more-info').appendTo('#cn-notice-text');

    });
    
})(jQuery);