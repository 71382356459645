(function($) {
	
	$.ajaxSetup({ cache: false });
	
	var resizeTimer;
	
	function onResize(){
		scaleImages( $('body') );
	}
	
	function scaleImages( $container ) {
		$container.find(".scale-images").each(function() {
			var image_container = $(this);
			scaleImage(image_container);
		}); 
	}
	
	function scaleImage( $image_container ){
		if( $image_container.hasClass('fadein') ) return;
		
		$image_container.find('img,video').each(function() {
			var $image = $(this);
			$image.removeClass('scale-reverse');
			
			if( $image.height() ==  0) {
				setTimeout(function(){
					scaleImage($image_container);
				}, 50);
			}else{
						
				var $image_smaller_height = $image_container.height() >= $image.height();
				var $image_landscape = ( $image.width() - $image.height() ) >= 0;
				var $container_landscape = ( $image_container.width() - $image_container.height() ) >= 0;
				
				if( $image_landscape && !$container_landscape ){
					$image.addClass('scale-reverse');
				}else if( $image_landscape && $container_landscape ){
					if( $image_smaller_height ){
						$image.addClass('scale-reverse');
					}else {
						setTimeout(function(){
							scaleImage($image_container);
						}, 50);
					}
				}
				
				$image_container.addClass('fadein');
			}
		});
	}

	function activateLoadMore(bool){
		if(bool){
			$('a.load-more').removeClass('disabled');
		}else {
			$('a.load-more').addClass('disabled');
		}
	}
    
    function loadMoreButton(){
    	
    	$( '.scale-images' ).ready(function() {
	    	$( this ).find('img').each(function(){
				var interchange = new Foundation.Interchange($(this));
			});
		});
		
		$('a.load-more').on('click', function(event){
			event.preventDefault();
			
			var $button = $(this);
			
			if( $button.hasClass( 'no-more' ) || $button.hasClass('disabled') ) return;
			
			activateLoadMore(false);
			
			var $container = $button.attr('data-container');
			var $next_page_url = $button.attr('data-next-url');
			var $button_replace = $button.attr('data-button-replace');
			var $button_text = $button.attr('data-button-text');
			
			$.ajax({
	            type        : 'GET',
	            url         : $button.attr('href'),
	            dataType    : 'html', 
	            cache       : false,
	        })
	        .done(function(data, state) {
	        	
	        	var $more = $( data ).find( $container ).html();
	        	
	        	if( $button_replace && $button_replace == 'false') {
	        		
					$( $container ).append($more);
					
					$.ajax({
			            type        : 'GET',
			            url         : $next_page_url,
			            dataType    : 'html', 
			            cache       : false,
			        })
			        .error(function (xhr, ajaxOptions, thrownError){
			            if( xhr.status >= 400 ) {
			                activateLoadMore(true);
							$button.addClass('no-more');
							$button.attr('href', '#');
							$button.attr('data-next-url', '#');
			            }
			        })
			        .success(function(data, state) {
			        	loadMoreButton();
						activateLoadMore(true);
						
						$button.attr('href', $next_page_url);
						
						var $page_regex = /page\/([(0-9)+])\/+/igm;
						var $page_regex_matches = $page_regex.exec( $next_page_url );
						var $next_page = parseInt( $page_regex_matches[1] ) + 1;
						
						var $nopaging_url = $next_page_url.substring(0, $next_page_url.indexOf("/page/") );
						var $new_next_page_url = $nopaging_url + '/page/' + $next_page + '/';
						
						$button.attr('data-next-url', $new_next_page_url);
			        });
				}
				else {
					$button.replaceWith( $more );
					loadMoreButton();
				}
				
				new Foundation.Equalizer( $( $container ) ).applyHeight();
				
	        });
			
		});
		
	}
    
    function init(){ 
    	loadMoreButton();
    }   
    
    $(document).ready(function(){
    	init();
    });
    
    $(window).bind('resize orientationchange', function(e) {
    	$('.scale-images').removeClass('fadein');
		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(onResize, 50);
	});
    
//    $(window).on('load', function(){
//    	scaleImages( $('body') );
//	});

    $(document).on('replaced.zf.interchange', function(event){
    	var $image = $(event.target);
		var $image_container = $image.closest('.scale-images');
		$image_container.removeClass('fadein');
		
		if( $image_container.length ){
			scaleImage($image_container);
		}
    });
    
    $(document).on('postequalized.zf.equalizer', function(event){
    	var $image = $(event.target);
		var $image_container = $image.closest('.scale-images');
		
		if( $image_container.length ){
			$image_container.removeClass('fadein');
			scaleImage($image_container);
		}else{
			scaleImages( $('body') );
		}
    });
    
    $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
	});
    
})(jQuery);